<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu />
      </div>
      <div class="columns">
        <div class="column is-narrow-desktop is-hidden-mobile is-hidden-touch sbar">
          <Sidebar />
        </div>
        <div class="column">
          <div>
            <div class="is-flex is-justify-content-space-between block">
              <div>
                <h1 class="title">Position Calculator</h1>
                <h2 class="subtitle">
                  Calculate your PnL and Risk/Reward
                </h2>
              </div>
            </div>

            <div class="block">
              <label class="label" for="entry">Entry Price:</label>
              <div class="field has-addons">
                <div class="control" style="flex: 1">
                  <input
                      v-model.number="entry"
                      id="entry"
                      class="input"
                      type="number"
                      placeholder="Entry Price"
                  />
                  <!--                  <p class="help">In cryptocurrencies like USDT, BTC, etc. or fiat currencies</p>-->
                </div>
              </div>
            </div>

            <div class="block">
              <label class="label" for="size">Order Size:</label>
              <div class="field has-addons">
                <div class="control" style="flex: 1">
                  <input
                      v-model.number="size"
                      id="size"
                      class="input"
                      type="number"
                      placeholder="Order Size"
                  />
<!--                  <p class="help">In cryptocurrencies like USDT, BTC, etc. or fiat currencies</p>-->
                </div>
              </div>
            </div>

            <label class="label">Size Type:</label>
            <div class="field has-addons">
              <div class="control">
                <label class="radio">
                  <input v-model="size_type" value="fiat" type="radio" name="size_type">
                  Fiat (USD, EUR, etc.)
                </label>
                <label class="radio">
                  <input v-model="size_type" value="coin" type="radio" name="size_type">
                  Coin (BTC, ETH, etc.)
                </label>
<!--                <p class="help">Maker = limit order, Taker = market order</p>-->
              </div>
            </div>

            <div class="block">
              <label class="label" for="leverage">Leverage:</label>
              <div class="field has-addons">
                <div class="control" style="flex: 1">
                  <input
                      v-model.number="leverage"
                      id="leverage"
                      class="input"
                      type="number"
                      placeholder="Leverage"
                  />
                  <!--                  <p class="help">In cryptocurrencies like USDT, BTC, etc. or fiat currencies</p>-->
                </div>
              </div>
            </div>

            <label class="label">Direction:</label>
            <div class="field has-addons">
              <div class="control">
                <label class="radio">
                  <input v-model="direction" value="long" type="radio" name="direction">
                  Buy (Long)
                </label>
                <label class="radio">
                  <input v-model="direction" value="short" type="radio" name="direction">
                  Sell (Short)
                </label>
                <!--                <p class="help">Maker = limit order, Taker = market order</p>-->
              </div>
            </div>

            <div class="block">
              <label class="label" for="target">Target:</label>
              <div class="field has-addons">
                <div class="control" style="flex: 1">
                  <input
                      v-model.number="target"
                      id="target"
                      class="input"
                      type="number"
                      placeholder="Target Price"
                  />
                  <!--                  <p class="help">In cryptocurrencies like USDT, BTC, etc. or fiat currencies</p>-->
                </div>
              </div>
            </div>

            <div class="block">
              <label class="label" for="stopLoss">Stop Loss:</label>
              <div class="field has-addons">
                <div class="control" style="flex: 1">
                  <input
                      v-model.number="stopLoss"
                      id="stopLoss"
                      class="input"
                      type="number"
                      placeholder="Stop Loss Price"
                  />
                  <!--                  <p class="help">In cryptocurrencies like USDT, BTC, etc. or fiat currencies</p>-->
                </div>
              </div>
            </div>

            <p class="is-size-5">Initial Margin: {{ cost || null }}</p>
            <p class="is-size-5">Profit: {{ profit || null }}</p>
            <p class="is-size-5">Loss: {{ loss || null }}</p>
            <p class="is-size-5">Risk-Reward Ratio: {{ riskRewardRatio }}</p>
          </div>
<!--          <div>-->
<!--            <form>-->
<!--              <label for="direction">Direction:</label>-->
<!--              <select id="direction" v-model="direction">-->
<!--                <option value="long">Long</option>-->
<!--                <option value="short">Short</option>-->
<!--              </select>-->
<!--              <br>-->
<!--              <label for="entry">Entry Price:</label>-->
<!--              <input id="entry" v-model.number="entry" type="number" step="0.01"/>-->
<!--              <br>-->
<!--              <label for="exit">Exit Price:</label>-->
<!--              <input id="exit" v-model.number="exit" type="number" step="0.01"/>-->
<!--              <br>-->
<!--              <label for="size">Position Size:</label>-->
<!--              <input id="size" v-model.number="size" type="number" step="0.01"/>-->
<!--              <br>-->
<!--              <label for="balance">Balance:</label>-->
<!--              <input id="balance" v-model.number="balance" type="number" step="0.01"/>-->
<!--              <br>-->
<!--              <label for="leverage">Leverage:</label>-->
<!--              <input id="leverage" v-model.number="leverage" type="number" step="0.01"/>-->
<!--              <br>-->
<!--              <button type="button" @click="calculatePnL">Calculate PnL</button>-->
<!--            </form>-->
<!--            <div>-->
<!--              PnL: {{ pnl }}-->
<!--              <br>-->
<!--              Risk: {{ risk }}-->
<!--              <br>-->
<!--              Reward: {{ reward }}-->
<!--              <br>-->
<!--              Risk-Reward Ratio: {{ riskRewardRatio }}-->
<!--              <br>-->
<!--              Margin Used: {{ margin }}-->
<!--              <br>-->
<!--              Liquidation Price: {{ liquidationPrice }}-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import { auth, db } from "@/firebase";

export default {
  name: "AccountCalculatorRR",
  components: {
    Sidebar,
    Mobilemenu,
  },
  data() {
    return {
      entry: null,
      target: null,
      stopLoss: null,
      direction: 'long',
      size: null,
      size_type: 'fiat',
      leverage: 1,
    };
  },
  computed: {
    profit() {
      if (this.direction === 'long') {
        if (this.size_type === 'fiat') {
          return (this.target - this.entry) * (this.size / this.entry);
        } else {
          return (this.target - this.entry) * this.size;
        }
      } else {
        if (this.size_type === 'fiat') {
          return (this.entry - this.target) * (this.size / this.entry);
        } else {
          return (this.entry - this.target) * this.size;
        }
      }
      // return (this.target - this.entry) * this.size
    },
    loss() {
      if (this.direction === 'long') {
        if (this.size_type === 'fiat') {
          return (this.entry - this.stopLoss) * (this.size / this.entry);
        } else {
          return (this.entry - this.stopLoss) * this.size;
        }
      } else {
        if (this.size_type === 'fiat') {
          return (this.stopLoss - this.entry) * (this.size / this.entry);
        } else {
          return (this.stopLoss - this.entry) * this.size;
        }
      }
      // return -(this.entry - this.stopLoss) * this.size
    },
    cost() {
      if (this.size_type === 'fiat') {
        return this.entry * (this.size / this.entry) / this.leverage;
      } else {
        return this.entry * this.size / this.leverage;
      }
    },
    riskRewardRatio() {
      let result;
      if (this.direction === 'long') {
        result = (this.target - this.entry) / (this.entry - this.stopLoss);
      } else {
        result = (this.entry - this.stopLoss) / (this.target - this.entry);
      }
      if (isNaN(result)) {
        return null;
      } else {
        return result.toFixed(2);
      }
    }
  },
  methods: {
    calculatePnL() {
      if (this.direction === 'long') {
        this.pnl = (this.exit - this.entry) * this.size
        this.risk = this.entry - this.exit
        this.reward = this.exit - this.entry
      } else {
        this.pnl = (this.entry - this.exit) * this.size
        this.risk = this.exit - this.entry
        this.reward = this.entry - this.exit
      }
      this.riskRewardRatio = this.risk / this.reward
      this.margin = (this.entry * this.size) / 2
      // this.liquidationPrice = (this.balance * 0.5) / (this.size /
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  flex: 1;
  display: flex;
  transition: all 0.3s ease-in-out;
  .card-content {
    width: 100%;
    .title {
      flex: 1;
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    transform: scale(1.02, 1.02);

    &::after {
      opacity: 1;
    }
  }
}
.radio:hover, .checkbox:hover {
  color: inherit;
}
</style>
